import React from "react";
import { FormattedMessage, Link } from "gatsby-plugin-intl";
import { graphql } from "gatsby";
import { micromark } from "micromark";

import Layout from "../components/layout";
import Breadcrumbs from "../components/breadcrumbs";

const PrioritisationFramework = (props: any) => {
  const data = props.data.allMarkdownRemark.nodes[0].frontmatter;
  const { athlete, sport, system, body } = data[props.pageContext.language];
  const bodyHTML = micromark(body);

  return (
    <Layout>
      <Breadcrumbs
        parentPageUrl="/resources"
        parentPageTitle={
          <FormattedMessage
            id="resources.back_to_resources"
            defaultMessage="Resources"
          />
        }
        currentPageTitle={
          <FormattedMessage
            id="prioritisation_framework"
            defaultMessage="Prioritisation Framework"
          />
        }
      ></Breadcrumbs>
      <h1 className="block-title mb-lg-5 mb-2">
        <FormattedMessage
          id="prioritisation_framework"
          defaultMessage="Prioritisation Framework"
        />
      </h1>
      <div className="prioritisation-framework">
        <div className="prioritisation-framework__y-axis-container">
          <FormattedMessage id="high_priority" defaultMessage="High Priority" />
          <div className="prioritisation-framework__y-axis"></div>
        </div>
        <div className="prioritisation-framework__block-container">
          <div className="prioritisation-framework__block">
            <p className="prioritisation_framework__question font-bold">
              {athlete.question}
            </p>
            <p className="prioritisation_framework__info">{athlete.help}</p>
          </div>
        </div>
        <div className="prioritisation-framework__block-container">
          <div className="prioritisation-framework__block">
            <p className="prioritisation_framework__question font-bold">
              {sport.question}
            </p>
            <p className="prioritisation_framework__info">{sport.help}</p>
          </div>
        </div>
        <div className="prioritisation-framework__block-container">
          <div className="prioritisation-framework__block">
            <p className="prioritisation_framework__question font-bold">
              {system.question}p
            </p>
            <p className="prioritisation_framework__info">{system.help}</p>
          </div>
        </div>
        <div className="prioritisation-framework__x-axis-container">
          <div className="prioritisation-framework__x-axis"></div>
          <p className="text-end">
            {" "}
            <FormattedMessage
              id="high_breadth_of_impact"
              defaultMessage="High Breadth of Impact"
            />
          </p>
        </div>
      </div>
      <div dangerouslySetInnerHTML={{ __html: bodyHTML }}></div>

    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      filter: { fields: { slug: { eq: "/prioritisation-framework/" } } }
    ) {
      nodes {
        frontmatter {
          title
          en {
            body
            athlete {
              question
              help
            }
            sport {
              help
              question
            }
            system {
              help
              question
            }
          }
          cy {
            body
            athlete {
              question
              help
            }
            sport {
              help
              question
            }
            system {
              help
              question
            }
          }
        }
      }
    }
  }
`;

export default PrioritisationFramework;
